<template>
	<div class="section_container theme_join">
		<div class="section_header">
			<h1 class="logo">
				<a>
					<img src="@/assets/images/logo_do.png" alt="다우오피스" title="다우오피스" />
				</a>
			</h1>
			<div class="snb">
				<h2 class="logo">
					<a>
						<img src="@/assets/images/logo_daou_220519.png" alt="다우기술" title="다우기술" />
					</a>
				</h2>
			</div>
		</div>
		<div class="section_body">
			<div class="wrap_notice">
				<p class="txt_big">다우오피스 시스템 작업 안내</p>
				<div class="wrap_content">
					<p class="tit">작업 일시</p>
					<p class="data">2021.08.28(토) 11:00 ~15:00 (4시간)</p>
					<p class="tit">작업내용</p>
					<p class="data">시스템 점검</p>
					<div class="wrap_desc">
						<p class="desc caution">* 작업시간은 점검 내용에 따라 변경될 수 있습니다.</p>
						<p class="txt">
							시스템 점검으로 인하여<br />
							<strong>작업시간 내 다우오피스의 주문 및 결제가 일시 중단</strong>됩니다. <br />
							고객 여러분의 양해 부탁드립니다.<br />
							감사합니다.
						</p>
					</div>
				</div>
				<a class="btn_primary" href="https://www.daouoffice.com/">
					<span class="txt">다우오피스 홈페이지 바로가기</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			description: '',
		};
	},
	created() {
		this.description = window.__env.underConstruction.description;
	},
};
</script>

<style scoped>
.description {
	text-align: center;
	font-weight: bold;
	font-size: 20px;
	margin-top: 200px;
}
</style>
